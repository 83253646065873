import './instrument'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import LogRocket from 'logrocket'
import Intercom from '@intercom/messenger-js-sdk'
import './index.css'

LogRocket.init('umvlca/um-prod')
Intercom({ app_id: 'c4if24w2' })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
  <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
